<template>
  <div class="app-container">
    <div>
      <el-button type="primary"  size="small" @click="getList" plain>
        <el-icon><Refresh /></el-icon><span  > 刷新</span>
      </el-button>
      <!-- <el-button @click="delall()" type="danger" size='small' plain>批量删除</el-button> -->
    </div>
    <el-table :height="contentStyleObj" v-loading="listLoading" :key="tableKey" :data="list" border fit highlight-current-row style="width: 100%;margin-top:15px;" @sort-change="sortChange">
      <!-- <el-table-column type="selection" align="center" width="35" /> -->
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="系统接收时间" width="200" >
        <template #default="scope">
          <span>{{ $parseTime(scope.row.CreatedAt,'{y}-{m}-{d} {h}:{i}:{s}')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="接收时间" width="200" >
        <template #default="scope">
          <span>{{ $parseTime(scope.row.receive_time,'{y}-{m}-{d} {h}:{i}:{s}')}}</span>
        </template>
      </el-table-column>

      <el-table-column label="接收手机号" width="200" >
        <template #default="scope">
          <span>{{ scope.row.mobile_receive }}</span>
        </template>
      </el-table-column>

      <el-table-column label="来源" width="200" >
        <template #default="scope">
          <span>{{ scope.row.mobile_send }}</span>
        </template>
      </el-table-column>

      <el-table-column label="内容" >
        <template #default="scope">
          <span>{{ scope.row.content }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import { smsRecList } from "@/api/company";
export default {
  name: "sms",
  data(){
    return {
      listQuery:{
        page:1,
        limit:20
      },
      total:0,
      list:[],
      contentStyleObj:{}, //高度变化
      listLoading:false
    }
  },
  created(){
    this.contentStyleObj=this.$getHeight(246)
    this.getList()
  },
  methods:{
    getList(){
      this.listLoading = true;
      smsRecList(this.listQuery).then(res=>{
        this.listLoading = false;
        if(res.data.data.list){
          this.list = res.data.data.list
          this.total = res.data.data.total;
        }else{
          this.list = []
        }
      })
    }
  }
}
</script>

<style>

</style>